import { HelpersModule } from "../helpers/helpers";
import { NavigationModule } from "../navigation";
import { HeroModule } from "../heros/hero";
import { NewHeroModule } from "../heros/newhero";
import { HospitalConsultantsIntroModule } from "../hospitalConsultantsIntro";
import { EnquiryFormModule } from "../enquiryform";
import { AccordionListModule } from "../accordionlist";
import { TreatmentOverviewModule } from "../treatmentOverview";
import { CarouselHomeModule } from "../Carousels/home";
import { VideoModalModule } from "../modals/video";
import { HomePageVideoModule } from "../modals/homeVideo";
import { ConsultantOverviewModule } from "../consultantOverview";
import { ArticleListModule } from "../articleList";
import { TestimonialsListModule } from "../testimonialsList";
import { CarouselHeroHomeModule } from "../Carousels/heroHomeCarousel";
import { VideoCarouselModule } from "../Carousels/videoCarousel";
import { VideoPlaylistModule } from "../Carousels/videoPlaylist";
import { RatingsSummaryModule } from "../ratings/ratings-summary";
import { RatingsListModule } from "../ratings/ratings-list";
import { SectionTextModule } from "../sectiontext";
import { BodyMapModule } from "../bodymap";
import { LoginRegisterModule } from "../loginregister";
import { DownloadsListModule } from "../downloadslist";
import { ConsultantFormSearch } from "../consultantFormSearch";
import { ToggleModule } from "../toggle/toggle";
import { CookieSettingsSetup } from "../cookieSettings";

export class NestedLoader {
    constructor() {
        this.init();
    }

    loadNavigation() {
        const navigationModule = new NavigationModule();
    }

    loadHeros() {
        const heroModule = new HeroModule();
        const newHeroModule = new NewHeroModule();
        const videoModal = new VideoModalModule();  
        const homePageVideoModule = new HomePageVideoModule();
        const carouselheroHomeModule = new CarouselHeroHomeModule();
    }

    loadFinders() {
        const hospitalConsultantsIntroModule = new HospitalConsultantsIntroModule();
    }

    loadForms() {
        const customerEnquiryFormModule = new EnquiryFormModule();
        const accordionListModule = new AccordionListModule();
        const loginRegisterModule = new LoginRegisterModule();
        const downloadsListModule = new DownloadsListModule();
    }

    loadTreatmentOverview() {
        const treatmentOverviewModule = new TreatmentOverviewModule(new HelpersModule());
    }

    loadGeneric() {
        const videoModal = new VideoModalModule();    
        const carouselHomeModule = new CarouselHomeModule();
    }
    
    loadConsultantOverview() {
        const consultantOverviewModule = new ConsultantOverviewModule(new HelpersModule());
    }

    loadArticles() {
        const articleListModule = new ArticleListModule(new HelpersModule());
    }

    loadTestimonials() {
        const testimonialsListModule = new TestimonialsListModule(new HelpersModule());
    }

    loadRatings() {
        const ratingsSummaryModule = new RatingsSummaryModule();
        const ratingsListModule = new RatingsListModule();
    }

    loadSectionText() {
        const sectionTextModule = new SectionTextModule();
    }

    loadBodyMap() {
        const bodyMapModule = new BodyMapModule();
    }

    loadConsultantSearch() {
        const consultantFormSearch = new ConsultantFormSearch();
    }

    init() {
        
        let bodyElement = document.getElementsByTagName('body')[0];
        let dataType = bodyElement.dataset.dtype;

        // we are always loading heros script as there is one on each page.
        this.loadNavigation();
        this.loadHeros();
        this.loadFinders();
        this.loadForms();
        this.loadGeneric();
        this.loadBodyMap();

        if (dataType === 'articlelist') this.loadArticles();
        if (dataType === 'testimonialslist') this.loadTestimonials(); 
        if (dataType === 'consultantsearch') this.loadConsultantSearch();

        this.loadSectionText(); 

        // TODO - Load conditionally, discuss with Lea
        if (true) {
            this.loadTreatmentOverview();
        }

        if (dataType === 'consultantfolder') {
            this.loadConsultantOverview();
        }

        const videoCarouselModule = new VideoCarouselModule();
        const videoPlaylistModule = new VideoPlaylistModule();

        if (document.title === 'Patient feedback') this.loadRatings();

        const toggleModule = new ToggleModule();

        CookieSettingsSetup();
    }

}