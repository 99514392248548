export class VideoModalModule {

    constructor() {
        this.init()
    }

    init() {

        if ($(".modal-video__link")) {
            var videoSrc;

            $(".modal-video__link").on("click", function () {
                videoSrc = $(this).data("src");
                $("#video-youtube").attr('src', videoSrc);

            });

            $('#modal-video').on('shown.bs.modal', function () {

            });

            $('body').on('hidden.bs.modal', '.modal', function () {
                $(this).removeData('bs.modal');
                $("#video-youtube").attr('src', videoSrc);
            });
        }
    }
}




